import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import { useIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/App/Layout";
import NavbarTwo from "../components/App/NavbarTwo";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import FaqBanner from "../components/FAQ/FaqBanner";
import ProductsList from "../components/MachineLearningAISolutions/ProductsList";
import { useStaticQuery } from "gatsby";

const FAQ = () => {
  const intl = useIntl();
  const {
    allContentfulFaqPage: { nodes: dataFaq },
    allContentfulMainSlider: { nodes: faq },
  } = useStaticQuery(query);
  const faqImage =
    intl.locale === "en"
      ? faq[0].faqImage.fluid
      : faq[0].faqImageFr.fluid || faq[0].faqImage.fluid;
  const canonical =
    intl.locale === "en"
      ? `${process.env.GATSBY_MAIN_URL}/faq`
      : `${process.env.GATSBY_MAIN_URL}/fr/faq`;
  const faqFixedImage =
    intl.locale === "en"
      ? faq[0].faqImage.fixed.src
      : faq[0].faqImageFr.fixed.src || faq[0].faqImage.fixed.src;

  return (
    <Layout title="FAQ Page" canonical={canonical} image={faqFixedImage}>
      <NavbarTwo />
      <FaqBanner faqImage={faqImage} />
      <PageBanner
        pageTitle={intl.formatMessage({ id: "faq" })}
        homePageText={intl.formatMessage({ id: "home" })}
        homePageUrl="/"
        activePageText={intl.formatMessage({ id: "faq" })}
      />
      <div className="ptb-50">
        <div className="container mb-5">
          <div className="faq-accordion">
            <Accordion>
              {intl.locale === "en" &&
                dataFaq.map((que, i) => {
                  if (
                    que?.questionEnglish?.questionEnglish &&
                    que?.answerEnglish?.answerEnglish
                  ) {
                    return (
                      <AccordionItem key={i}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {que?.questionEnglish?.questionEnglish}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p> {que?.answerEnglish?.answerEnglish}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  } else {
                    return "";
                  }
                })}
              {intl.locale !== "en" &&
                dataFaq.map((que, i) => {
                  if (
                    que?.questionFrench?.questionFrench &&
                    que?.answerFrench?.answerFrench
                  ) {
                    return (
                      <AccordionItem key={i}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {que?.questionFrench?.questionFrench}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p> {que?.answerFrench?.answerFrench}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  } else {
                    return "";
                  }
                })}
            </Accordion>
          </div>
        </div>
        <ProductsList />
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulFaqPage(sort: { fields: createdAt }) {
      nodes {
        questionFrench {
          questionFrench
        }
        questionEnglish {
          questionEnglish
        }
        answerEnglish {
          answerEnglish
        }
        answerFrench {
          answerFrench
        }
      }
    }
    allContentfulMainSlider {
      nodes {
        faqImage {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
        faqImageFr {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
      }
    }
  }
`;

export default FAQ;
